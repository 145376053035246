import { useState, useEffect } from 'react';
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'src/types/User';
import { Role, RoleName } from 'src/types/Role';
import { assignRolesToUser, getRoles } from 'src/api/RoleQueries';
import DeleteUserModal from './DeleteUserModal';
import PaginatedTable from 'src/components/PaginatedTable';
import { LoadingButton } from '@mui/lab';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface UsersTableProps {
  users: User[];
  setUsers: (users: User[]) => void;
}

const UsersTable = ({ users, setUsers }: UsersTableProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [allRoles, setAllRoles] = useState<Role[]>([]);
  const [deletingUserId, setDeletingUserId] = useState<string>('');
  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const fetchRoles = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseRoles = await getRoles(accessToken);
    if (responseRoles) {
      setAllRoles(responseRoles);
    }
  };

  useEffect(() => {
    void fetchRoles();
  }, []);

  const handleRoleChange = async (user: User, roleNames: RoleName[]) => {
    const findRoles = allRoles.filter((role) => roleNames.includes(role.name));

    const accessToken = await getAccessTokenSilently();
    const updatedUser = await assignRolesToUser(
      accessToken,
      user.id,
      findRoles.map((role) => role.id),
    );
    if (updatedUser) {
      const updatedUsers = users.map((u) => {
        if (u.id === user.id) {
          return { ...u, roles: findRoles };
        }
        return u;
      });
      setUsers(updatedUsers);
    }
  };

  const handleDelete = (id: string) => {
    setDeletingUserId(id);
    setOpenDeleteUser(true);
  };

  const columns = [
    { id: 'email', label: 'Email' },
    { id: 'organization', label: 'Organization' },
    { id: 'roles', label: 'Roles' },
    { id: 'delete', label: 'Delete' },
  ];

  const renderRow = (user: User) => (
    <TableRow hover key={user.email}>
      <TableCell>
        <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
          {user.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
          {user.organization.name}
        </Typography>
      </TableCell>
      <TableCell>
        <FormControl size="small">
          <Select
            multiple
            size="small"
            value={user.roles.map((role) => role.name)}
            onChange={(e) => void handleRoleChange(user, e.target.value as RoleName[])}
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {allRoles.map((role) => (
              <MenuItem key={role.id} value={role.name}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <LoadingButton onClick={() => handleDelete(user.id)}>
          <DeleteTwoToneIcon fontSize="small" />
        </LoadingButton>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <PaginatedTable columns={columns} data={users} renderRow={renderRow} />
      <DeleteUserModal
        open={openDeleteUser}
        onClose={() => setOpenDeleteUser(false)}
        userId={deletingUserId}
        users={users}
        setUsers={setUsers}
      />
    </>
  );
};

export default UsersTable;
