import { Button, Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { LoadingButton } from '@mui/lab';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`,
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`,
);

function Hero() {
  const { logout } = useAuth0();

  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
      <Grid spacing={{ xs: 6, md: 10 }} justifyContent="center" alignItems="center" container>
        <Grid item md={10} lg={10} mx="auto">
          <TypographyH1 sx={{ mb: 2 }} variant="h1">
            Lattice Dashboard
          </TypographyH1>
          <TypographyH2 sx={{ lineHeight: 1.5, p: 2 }} variant="h4" color="text.secondary" fontWeight="normal">
            Seamlessly connect your biotechnology projects.
          </TypographyH2>
          <LoadingButton
            component={RouterLink}
            to="/home/"
            variant="contained"
            sx={{ m: 2 }}
            color="primary"
            size="large"
            loading={false}
          >
            Get started
          </LoadingButton>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={() => void logout({ logoutParams: { returnTo: process.env.REACT_APP_FRONTEND_URI } })}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
