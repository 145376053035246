import { Resource } from 'src/types/Resource';
import { apiFetch } from './APIFetch';

export const getAllResources = async (accessToken: string): Promise<Resource[] | null> => {
  return apiFetch({
    path: '/resources/all',
    method: 'GET',
    accessToken,
  });
};
