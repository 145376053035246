import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Container, Grid } from '@mui/material';
import PageHeader from './PageHeader';
import IconCard from './IconCard';
import { menuConfig } from 'src/layouts/SidebarLayout/Sidebar/SidebarMenu/menu.config';
import { AuthorizedComponent } from 'src/components/AuthorizedComponent';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';

function Home() {
  const { roles, enabledResources } = useContext(SidebarContext);

  // TODO: Only put some items here, the ones we want as a "quick access"
  // Or maybe recent items
  const menuItems = menuConfig.flatMap((section) => section.items);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {menuItems.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.path}>
              <AuthorizedComponent
                requiredRoles={item.requiredRoles}
                requiredResources={item.requiredResources}
                userRoles={roles}
                userEnabledResources={enabledResources}
              >
                <IconCard icon={item.icon} title={item.title} path={item.path} />
              </AuthorizedComponent>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Home;
