import { FC, useState, useEffect } from 'react';
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useAuth0 } from '@auth0/auth0-react';
import { deleteOrganization, updateOrganizationEnabledResources } from 'src/api/OrganizationQueries';
import { getAllResources } from 'src/api/ResourceQueries';
import { Organization } from 'src/types/Organization';
import { Resource, ResourceName } from 'src/types/Resource';
import { RoleName } from 'src/types/Role';
import PaginatedTable from 'src/components/PaginatedTable';
import { LoadingButton } from '@mui/lab';

interface OrganizationsTableProps {
  className?: string;
  organizations: Organization[];
  setOrganizations: (organization: Organization[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OrganizationsTable: FC<OrganizationsTableProps> = ({ organizations, setOrganizations }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [allResources, setAllResources] = useState<Resource[]>([]);
  const [deletingOrg, setDeletingOrg] = useState<boolean>(false);
  const [updatingOrgId, setUpdatingOrgId] = useState<string | null>(null);

  const fetchResources = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseResources = await getAllResources(accessToken);
    if (responseResources) {
      setAllResources(responseResources);
    }
  };

  useEffect(() => {
    void fetchResources();
  }, []);

  const handleResourceChange = async (selectedOrganization: Organization, resourceNames: ResourceName[]) => {
    setUpdatingOrgId(selectedOrganization.id);
    const findResources = allResources.filter((resource) => resourceNames.includes(resource.name));
    const accessToken = await getAccessTokenSilently();
    const newOrg = await updateOrganizationEnabledResources(
      accessToken,
      selectedOrganization.id,
      findResources.map((role) => role.id),
    );
    if (newOrg) {
      const newOrgIndex = organizations.findIndex((org) => org.id === selectedOrganization.id);
      const newOrgs = [...organizations];
      newOrgs[newOrgIndex] = newOrg;
      setOrganizations(newOrgs);
    }
    setUpdatingOrgId(null);
  };

  const getAdmins = (organization: Organization) => {
    return organization.users
      .filter((user) => user.roles.some((role) => role.name === ('organization-admin' as RoleName)))
      .map((user) => user.email)
      .join('\n');
  };

  const handleDelete = async (id: string) => {
    setDeletingOrg(true);
    const accessToken = await getAccessTokenSilently();
    const deletedOrg = await deleteOrganization(accessToken, id);
    if (deletedOrg) {
      setOrganizations(organizations.filter((org) => org.id !== id));
    }
    setDeletingOrg(false);
  };

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'enabledResources', label: 'Enabled resources' },
    { id: 'admins', label: 'Admins' },
    { id: 'delete', label: 'Delete' },
  ];

  const renderRow = (org: Organization) => (
    <TableRow hover key={org.name}>
      <TableCell>
        <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
          {org.name}
        </Typography>
      </TableCell>
      <TableCell>
        <FormControl size="small">
          <Select
            disabled={updatingOrgId === org.id}
            multiple
            size="small"
            value={org.enabledResources.map((resource) => resource.name)}
            onChange={(e) => void handleResourceChange(org, e.target.value as ResourceName[])}
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {allResources.map((role) => (
              <MenuItem key={role.id} value={role.name} disabled={updatingOrgId === org.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom>
          {getAdmins(org)}
        </Typography>
      </TableCell>
      <TableCell>
        <LoadingButton onClick={() => void handleDelete(org.id)} loading={deletingOrg}>
          <DeleteTwoToneIcon fontSize="small" />
        </LoadingButton>
      </TableCell>
    </TableRow>
  );

  return <PaginatedTable columns={columns} data={organizations} renderRow={renderRow} />;
};

export default OrganizationsTable;
