import { Permission } from './Permission';
import { User } from './User';

export enum RoleName {
  SUPER_ADMIN = 'super-admin',
  ORGANIZATION_ADMIN = 'organization-admin',
  USER = 'user',
}

export interface Role {
  id: string;
  name: RoleName;
  permissions: Permission[];
  users: User[];
}
