import { toast } from 'react-hot-toast';

export interface FetchOptions<TBody> {
  path: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  accessToken: string;
  body?: TBody;
}

export const apiFetch = async <TResponse, TBody = undefined>({
  path,
  method,
  accessToken,
  body,
}: FetchOptions<TBody>): Promise<TResponse | null> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...(body && { 'content-type': 'application/json' }),
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = data.message;
      toast.error(message);
      return null;
    }
  } catch (e) {
    console.log(e);
    toast.error('An error occurred while connecting to the server');
    return null;
  }
};
