import { List, ListSubheader } from '@mui/material';
import { ReactNode } from 'react';
import { AuthorizedComponent, AuthorizedProps } from '../../../../../components/AuthorizedComponent';

interface AuthorizedListProps extends AuthorizedProps {
  children: ReactNode;
  subheader?: ReactNode;
}

export const AuthorizedList = ({ children, subheader, ...authProps }: AuthorizedListProps) => {
  return (
    <AuthorizedComponent {...authProps}>
      {(() => {
        const childrenArray = Array.isArray(children) ? children : [children];
        const hasVisibleChildren = childrenArray.some((child) => child != null);

        if (!hasVisibleChildren) {
          return null;
        }

        return (
          <List
            component="div"
            subheader={
              subheader && (
                <ListSubheader component="div" disableSticky>
                  {subheader}
                </ListSubheader>
              )
            }
          >
            {children}
          </List>
        );
      })()}
    </AuthorizedComponent>
  );
};
