import { ChangeEvent, ReactNode, useState } from 'react';
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

interface Column {
  id: string;
  label: string;
}

interface PaginatedTableProps<T> {
  columns: Column[];
  data: T[];
  renderRow: (item: T) => ReactNode;
  initialLimit?: number;
}

const PaginatedTable = <T,>({ columns, data, renderRow, initialLimit = 5 }: PaginatedTableProps<T>) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(initialLimit);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    setPage(0); // Reset to first page when changing limit
  };

  const paginatedData = data.slice(page * limit, page * limit + limit);

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{paginatedData.map((item) => renderRow(item))}</TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default PaginatedTable;
