import { ReactNode } from 'react';
import { ResourceName } from 'src/types/Resource';
import { RoleName } from 'src/types/Role';

export interface AuthorizedProps {
  requiredRoles?: RoleName[];
  requiredResources?: ResourceName[];
  userRoles: RoleName[];
  userEnabledResources: ResourceName[];
}

interface AuthorizedComponentProps extends AuthorizedProps {
  children: ReactNode;
}

export const AuthorizedComponent = ({
  children,
  requiredRoles = [],
  requiredResources = [],
  userRoles,
  userEnabledResources,
}: AuthorizedComponentProps) => {
  const hasRequiredRoles = requiredRoles.length === 0 || requiredRoles.every((role) => userRoles.includes(role));

  const hasRequiredResources =
    requiredResources.length === 0 || requiredResources.every((resource) => userEnabledResources.includes(resource));

  if (!hasRequiredRoles || !hasRequiredResources) {
    return null;
  }

  return <>{children}</>;
};
