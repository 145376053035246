import { Organization } from 'src/types/Organization';
import { apiFetch } from './APIFetch';

export const getAllOrganizations = async (accessToken: string): Promise<Organization[] | null> => {
  return apiFetch<Organization[]>({
    path: '/organizations/all',
    method: 'GET',
    accessToken,
  });
};

export const updateOrganizationEnabledResources = async (
  accessToken: string,
  organizationId: string,
  enabledResourceIds: string[],
): Promise<Organization | null> => {
  return apiFetch<Organization, { enabledResourceIds: string[] }>({
    path: `/organizations/${organizationId}/update-enabled-resources`,
    method: 'PATCH',
    accessToken,
    body: { enabledResourceIds },
  });
};

export const getMyOrganization = async (accessToken: string): Promise<Organization | null> => {
  return apiFetch<Organization>({
    path: '/organizations/my-organization',
    method: 'GET',
    accessToken,
  });
};

export const createOrganization = async (accessToken: string, name: string): Promise<Organization | null> => {
  return apiFetch<Organization, { name: string }>({
    path: '/organizations',
    method: 'POST',
    accessToken,
    body: { name },
  });
};

export const deleteOrganization = async (accessToken: string, organizationId: string): Promise<Organization | null> => {
  return apiFetch({
    path: `/organizations/${organizationId}`,
    method: 'DELETE',
    accessToken,
  });
};
