import { User } from 'src/types/User';
import { apiFetch } from './APIFetch';
import { Role } from 'src/types/Role';

export const getRoles = async (accessToken: string): Promise<Role[] | null> => {
  return apiFetch<Role[]>({
    path: '/roles',
    method: 'GET',
    accessToken,
  });
};

export const assignRolesToUser = async (
  accessToken: string,
  userId: string,
  roleIds: string[],
): Promise<User | null> => {
  return apiFetch<User, { userId: string; roleIds: string[] }>({
    path: '/roles/assign-role-to-user',
    method: 'POST',
    accessToken,
    body: { userId, roleIds },
  });
};
