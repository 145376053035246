import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useAuth0 } from '@auth0/auth0-react';
import { createUser, createUserInOrganization } from 'src/api/UserQueries';
import { Role } from 'src/types/Role';
import { Organization } from 'src/types/Organization';
import { getRoles } from 'src/api/RoleQueries';
import { getAllOrganizations } from 'src/api/OrganizationQueries';
import { User } from 'src/types/User';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface CreateUserModalProps {
  onClose: () => void;
  open: boolean;
  isSuperAdmin: boolean;
  users: User[];
  setUsers: (users: User[]) => void;
}

function CreateUserModal({ onClose, open, isSuperAdmin, users, setUsers }: CreateUserModalProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userOrganization, setUserOrganization] = useState<string>('');
  const [allRoles, setAllRoles] = useState<Role[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(false);
  const [alreadyRun, setAlreadyRun] = useState(false);

  const fetchRoles = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseRoles = await getRoles(accessToken);
    if (responseRoles) {
      setAllRoles(responseRoles);
    }
  };

  const fetchOrganizations = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseOrganizations = await getAllOrganizations(accessToken);
    if (responseOrganizations) {
      setAllOrganizations(responseOrganizations);
    }
  };

  useEffect(() => {
    if (isSuperAdmin) {
      void fetchOrganizations();
    }
    void fetchRoles();
    setUserEmail('');
    setUserPassword('');
    setUserRoles([]);
    setUserOrganization('');
    setAlreadyRun(false);
    setLoading(false);
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const createUserRequest = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    let createdUser = null;
    const findRoles = allRoles.filter((role) => userRoles.includes(role.name));
    if (isSuperAdmin) {
      const findOrganization = allOrganizations.find((org) => org.name === userOrganization);
      if (!findOrganization) {
        setLoading(false);
        return;
      }
      createdUser = await createUser(
        accessToken,
        userEmail,
        userPassword,
        findOrganization.id,
        findRoles.map((role) => role.id),
      );
    } else {
      createdUser = await createUserInOrganization(
        accessToken,
        userEmail,
        userPassword,
        findRoles.map((role) => role.id),
      );
    }
    if (createdUser) {
      setUsers([...users, createdUser]);
      setAlreadyRun(true);
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: '100%' }}>
          <Typography variant="h4">Create a new user.</Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            Create a new user within an organization and give them a role. Set a temporary password. The user will
            receive an invitation email to set their own.
          </Typography>
          <FormControl size="small" sx={{ width: '100%' }}>
            <TextField
              label="User email"
              variant="outlined"
              size="small"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </FormControl>
          <FormControl size="small" sx={{ width: '100%' }}>
            <TextField
              label="User password"
              variant="outlined"
              type="password"
              size="small"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </FormControl>
          {isSuperAdmin && (
            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel id="org-label">Organization</InputLabel>
              <Select
                label="Organization"
                sx={{ minWidth: 150 }}
                value={userOrganization}
                onChange={(e) => setUserOrganization(e.target.value)}
              >
                {allOrganizations.map((org) => {
                  return (
                    <MenuItem key={org.id} value={org.name}>
                      {org.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="role-label">Roles</InputLabel>
            <Select
              multiple
              size="small"
              value={userRoles}
              onChange={(e) => setUserRoles(e.target.value as SetStateAction<string[]>)}
              input={<OutlinedInput label="Roles" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {allRoles.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!alreadyRun && (
            <>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => void createUserRequest()}
                disabled={!userEmail || userRoles.length === 0 || (isSuperAdmin && !userOrganization)}
              >
                Create
              </LoadingButton>
            </>
          )}
          {alreadyRun && (
            <Typography variant="body1" color="success">
              The user has been succesfully created. They will receive an email with an invitation to set their password
              and log in.
            </Typography>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
export default CreateUserModal;
