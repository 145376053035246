import { Typography, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import CreateUserModal from './CreateUserModal';
import { User } from 'src/types/User';

interface PageHeaderProps {
  users: User[];
  setUsers: (users: User[]) => void;
}

function PageHeader({ users, setUsers }: PageHeaderProps) {
  const [openNewUser, setOpenNewUser] = useState(false);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Users
          </Typography>
          <Typography variant="subtitle2">
            See all the users in the system and modify their roles. Create new users.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            onClick={() => setOpenNewUser(true)}
            startIcon={<AddIcon fontSize="small" />}
          >
            New user
          </Button>
        </Grid>
      </Grid>
      <CreateUserModal
        open={openNewUser}
        users={users}
        setUsers={setUsers}
        onClose={() => setOpenNewUser(false)}
        isSuperAdmin
      />
    </>
  );
}

export default PageHeader;
