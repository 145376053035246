import { Box, FormControl, Modal, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

import { useAuth0 } from '@auth0/auth0-react';
import { createOrganization } from 'src/api/OrganizationQueries';
import { Organization } from 'src/types/Organization';
import toast from 'react-hot-toast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

interface CreateOrganizationModalProps {
  onClose: () => void;
  open: boolean;
  setOrganizations: (organizations: Organization[]) => void;
  organizations: Organization[];
}

function CreateOrganizationModal({ onClose, open, setOrganizations, organizations }: CreateOrganizationModalProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [organizationName, setOrganizationName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOrganizationName('');
    setLoading(false);
    onClose();
  };

  const createOrganizationRequest = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const result = await createOrganization(accessToken, organizationName);
    if (result) {
      setOrganizations([...organizations, result]);
      toast.success('Organization added successfully');
      handleClose();
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: '100%' }}>
          <Typography variant="h4">Create a new organization.</Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            Create a new organization. Once created, you can add users and enable resources for it.
          </Typography>
          <FormControl size="small" sx={{ width: '100%' }}>
            <TextField
              label="Organization name"
              variant="outlined"
              size="small"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </FormControl>

          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => void createOrganizationRequest()}
            disabled={!organizationName}
          >
            Create
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
export default CreateOrganizationModal;
