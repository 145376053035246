import { Paper, Typography, Box } from '@mui/material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigate } from 'react-router-dom';

interface IconCardProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title: string;
  path: string;
}

const IconCard = ({ icon: Icon, title, path }: IconCardProps) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        p: 3,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
      onClick={() => navigate(path)}
    >
      <Box sx={{ mb: 1 }}>
        <Icon sx={{ fontSize: 40 }} />
      </Box>
      <Typography variant="h5">{title}</Typography>
    </Paper>
  );
};

export default IconCard;
