import { Typography, Button, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import CreateOrganizationModal from './CreateOrganization';
import { Organization } from 'src/types/Organization';

interface PageHeaderProps {
  setOrganizations: (organizations: Organization[]) => void;
  organizations: Organization[];
}

function PageHeader({ setOrganizations, organizations }: PageHeaderProps) {
  const [openNewOrganization, setOpenNewOrganization] = useState(false);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Organizations
          </Typography>
          <Typography variant="subtitle2">
            See all the organizations in the system and modify their enabled resources. Create new organizations.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            onClick={() => setOpenNewOrganization(true)}
            startIcon={<AddIcon fontSize="small" />}
          >
            New organization
          </Button>
        </Grid>
      </Grid>
      <CreateOrganizationModal
        open={openNewOrganization}
        onClose={() => setOpenNewOrganization(false)}
        setOrganizations={setOrganizations}
        organizations={organizations}
      />
    </>
  );
}

export default PageHeader;
