import { Box, Modal, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'src/types/User';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { deleteUser } from 'src/api/UserQueries';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

interface DeleteUserModalProps {
  onClose: () => void;
  open: boolean;
  userId: string;
  users: User[];
  setUsers: (users: User[]) => void;
}

function CreateUserModal({ onClose, open, userId, users, setUsers }: DeleteUserModalProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [alreadyDeleted, setAlreadyDeleted] = useState(false);

  useEffect(() => {
    setAlreadyDeleted(false);
  }, []);

  const handleClose = () => {
    onClose();
  };

  const deleteUserRequest = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const deletedUser = await deleteUser(accessToken, userId);
    if (deletedUser) {
      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
    }
    setLoading(false);
    setAlreadyDeleted(true);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: '100%' }}>
          <Typography variant="h4">Delete user</Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            This action is irreversible. All entities associated with this user will be forever deleted. Are you sure
            you want to proceed?
          </Typography>
          {alreadyDeleted ? (
            <Typography variant="body1" color="error" sx={{ pb: 2 }}>
              User has been deleted.
            </Typography>
          ) : (
            <Stack spacing={2} direction="row">
              <LoadingButton
                variant="contained"
                color="error"
                onClick={() => void deleteUserRequest()}
                loading={loading}
                loadingPosition="start"
                startIcon={<DeleteTwoToneIcon />}
              >
                Delete
              </LoadingButton>
              <LoadingButton variant="outlined" onClick={handleClose} disabled={loading}>
                Cancel
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
export default CreateUserModal;
