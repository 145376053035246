import { RouteObject } from 'react-router';
import BaseLayout from 'src/layouts/BaseLayout';
import Status404 from './pages/errors/Status404';
import Login from './pages/auth/Login';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import SidebarLayout from './layouts/SidebarLayout';
import Home from './pages/home';
import Users from './pages/superAdmin/Users';
import Organizations from './pages/superAdmin/Organizations';

const ProtectedLoginPage = withAuthenticationRequired(Login);
const ProtectedHomePage = withAuthenticationRequired(Home);
const ProtectedUsersPage = withAuthenticationRequired(Users);
const ProtectedOrganizationsPage = withAuthenticationRequired(Organizations);
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <ProtectedLoginPage />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'home',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ProtectedHomePage />,
      },
    ],
  },
  {
    path: 'super-admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'users',
        element: <ProtectedUsersPage />,
      },
      {
        path: 'organizations',
        element: <ProtectedOrganizationsPage />,
      },
    ],
  },
];

export default routes;
