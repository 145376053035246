import { ListItem } from '@mui/material';
import { ReactNode } from 'react';
import { AuthorizedComponent, AuthorizedProps } from '../../../../../components/AuthorizedComponent';

interface AuthorizedListItemProps extends AuthorizedProps {
  children: ReactNode;
}

export const AuthorizedListItem = ({ children, ...authProps }: AuthorizedListItemProps) => {
  return (
    <AuthorizedComponent {...authProps}>
      <ListItem component="div">{children}</ListItem>
    </AuthorizedComponent>
  );
};
