import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import UsersTable from './UsersTable';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllUsers } from 'src/api/UserQueries';
import { User } from 'src/types/User';

function UsersPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState<User[]>([]);

  const fetchUsers = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseUsers = await getAllUsers(accessToken);
    if (responseUsers) {
      setUsers(responseUsers);
    }
  };

  useEffect(() => {
    void fetchUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader users={users} setUsers={setUsers} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <UsersTable users={users} setUsers={setUsers} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default UsersPage;
