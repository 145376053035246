import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllOrganizations } from 'src/api/OrganizationQueries';
import OrganizationsTable from './OrganizationsTable';
import { Organization } from 'src/types/Organization';

function OrganizationsPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchOrganizations = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseOrganizations = await getAllOrganizations(accessToken);
    if (responseOrganizations) {
      setOrganizations(responseOrganizations);
    }
  };

  useEffect(() => {
    void fetchOrganizations();
  }, []);

  return (
    <>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader organizations={organizations} setOrganizations={setOrganizations} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <OrganizationsTable organizations={organizations} setOrganizations={setOrganizations} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default OrganizationsPage;
